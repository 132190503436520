import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import ContactForm from "../components/ContactForm"
import { mediaQueries, fonts, weights, container, colors } from "../styles"
import FullWidthSection from "../components/FullWidthSection"

export default () => {
  const wrapperCss = css`
    text-align: center;
    font-family: ${fonts.sans};
    font-weight: ${weights.light};
    font-size: 16px;
    line-height: 1.7;

    ${mediaQueries.phoneLarge} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  `

  const headingCss = css`
    margin-bottom: 0;
    font-family: ${fonts.sans};
    font-weight: ${weights.bold};
    font-size: 21px;
    line-height: 1.7;

    ${mediaQueries.phoneLarge} {
      font-size: 18px;
      line-height: 2;
    }
  `

  const contactItem = css`
    min-height: 120px;

    ${mediaQueries.phoneLarge} {
      flex: 0 0 calc(50% - 20px);
      width: calc(50% - 20px);
    }
  `

  const contactItemWide = css`
    ${mediaQueries.phoneLarge} {
      flex: 0 0 100%;
      width: 100%;
      padding-top: 40px;
    }
  `

  return (
    <Layout
      headerData={{
        title: "お問い合わせフォーム",
        mobileMinHeight: "93vh",
        height: "400px",
      }}
    >
      <div>
        <ContactForm />
      </div>
      <FullWidthSection
        backgroundColor={colors.lightblue}
        css={css`
          min-height: 120px;
          padding: 40px 0;
          margin-top: 80px;

          ${mediaQueries.desktop} {
            margin-top: 40px;
            min-height: 120px;
            padding: 40px 0;
          }
        `}
      >
        <div css={[container.textOnly, wrapperCss]}>
          <div css={contactItem}>
            <h3 css={headingCss}>TEL</h3>
            <a href="tel:0338335681">03-3833-5681</a>
          </div>

          <div css={contactItem}>
            <h3 css={headingCss}>FAX</h3>
            <a href="fax:033833568">03-3833-5682</a>
          </div>

          <div css={(contactItem, contactItemWide)}>
            <h3 css={headingCss}>〒110-0005</h3>
            東京都台東区上野 3-18-13 今井ビル 2F
          </div>
        </div>
      </FullWidthSection>
    </Layout>
  )
}
